import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

function SideNav({ navItems, currentSection, currentItem }) {
  return (
    <nav className="sidenav md:sticky top-10 md:w-4/12 pl-2 md:pl-4 pr-6 md:pb-6 mb-8 md:mb-16 md:mt-20 border-l-2 border-gold">
      {navItems.map((item, i) => {
        return (
          <div className="mb-2 md:mb-6 last:mb-0" key={`${item.uid}--${i}`}>
            <Link to={`/${item.uid}`}>
              <h3
                className={cx(
                  "text-base uppercase font-semibold hover:text-gold mb-2",
                  {
                    "font-semibold text-gold":
                      currentSection === `/${item.uid}`,
                  }
                )}
              >
                {item.data.table_of_contents_heading}
              </h3>
            </Link>
            <ul className="hidden md:block">
              {item.data.subsections.map((subItem, subindex) => {
                const subsectionSlug = subItem.table_of_contents_label
                  ? subItem.table_of_contents_label
                      .toLowerCase()
                      .replaceAll(" ", "-")
                  : "";

                return (
                  <li
                    className={cx(
                      "text-sm text-charcoal hover:font-semibold mb-2",
                      {
                        "font-semibold active-item":
                          currentItem === `#${subsectionSlug}`,
                      }
                    )}
                    key={`item--${i}-${subindex}`}
                  >
                    <Link to={`/${item.uid}#${subsectionSlug}`}>
                      {subItem.table_of_contents_label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </nav>
  );
}

export default SideNav;
