import * as React from "react";
import PropTypes from "prop-types";
import { PrismicRichText } from "@prismicio/react";

import { Layout } from "../components/Layout";
import SideNav from "../components/slices/SideNav";
import Promo from "../components/Promo";

const AboutSchengenPage = ({ location, data }) => {
  const currentInfoSection = data.prismicSchengenInfoSection;
  const tableOfContents = data.allPrismicSchengenInfoSection.nodes;
  return (
    <Layout
      metaTitle={data.prismicSchengenInfoSection.data.meta_title}
      metaDescription={data.prismicSchengenInfoSection.data.meta_description}
    >
      <div className="h-6 md:h-10 bg-teal-alpha-30 mb-6 md:mb-10"></div>
      <div className="container flex flex-col md:flex-row items-start min-h-[70vh] antialiased">
        <SideNav
          navItems={tableOfContents}
          currentSection={location.pathname}
          currentItem={location.hash}
        />
        <section className="info-content md:w-7/12 pb-10">
          <h1 className="text-2xl md:text-3xl font-semibold">
            {currentInfoSection.data.page_heading}
          </h1>

          {currentInfoSection.data.subsections.map((subsection, i) => {
            const subsectionSlug =
              subsection.table_of_contents_label &&
              subsection.table_of_contents_label
                .toLowerCase()
                .replaceAll(" ", "-");

            return (
              <div
                key={`content-${i}`}
                id={subsectionSlug}
                className="pt-4 md:pt-10"
              >
                <h2 className="text-lg md:text-xl font-bold text-charcoal mb-4">
                  {subsection.subheading}
                </h2>
                <PrismicRichText field={subsection.body.richText} />
              </div>
            );
          })}
        </section>
      </div>
      <div className="pb-10">
        <Promo />
      </div>
    </Layout>
  );
};

AboutSchengenPage.propTypes = {
  data: PropTypes.shape({
    prismicAboutSchengenPage: PropTypes.shape({
      data: PropTypes.shape({
        body: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            primary: PropTypes.object,
            slice_type: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

export default AboutSchengenPage;
