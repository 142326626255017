import { Hero } from "./Hero";
import { Features } from "./Features";
import Reviews from "./Reviews";
import SideNav from "./SideNav";
import LeadGeneration from "./LeadGeneration";

// components by slice type
export const components = {
  hero: Hero,
  features: Features,
  reviews: Reviews,
  information_section: SideNav,
  lead_generation_block: LeadGeneration,
};
