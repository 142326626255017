import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import cx from "classnames";

import Logo from "../images/logo.svg";

export const Header = () => (
  <>
    <MobileNav />
    <header className="hidden container md:h-32 md:flex items-center justify-between">
      <Link to="/">
        <img src={Logo} alt="Schengen Simple logo" className="header-logo" />
      </Link>
      <nav>
        <ul className="space-x-8 flex flex-col items-end md:flex-row text-sm antialiased uppercase font-medium text-charcoal">
          <li className="hover:text-teal-light active:text-teal-light">
            <Link to="/">The app</Link>
          </li>
          <li className="hover:text-teal-light active:text-teal-light">
            <Link to="/about-the-schengen-area">About the Schengen area</Link>
          </li>
          <li className="hover:text-teal-light active:text-teal-light">
            <Link to="/contact">Contact us</Link>
          </li>
        </ul>
      </nav>
    </header>
  </>
);

const MobileNav = () => {
  const [showNav, setShowNav] = useState(false);
  return (
    <header className="mobile-header md:hidden">
      <div className="default-view container h-24 flex items-center justify-between">
        <Link to="/">
          <img src={Logo} alt="Schengen Simple logo" className="header-logo" />
        </Link>
        <div onClick={() => setShowNav(!showNav)}>
          {showNav ? (
            <StaticImage src="../images/close-icon.svg" alt="Close menu icon" />
          ) : (
            <StaticImage
              src="../images/hamburger-icon.svg"
              alt="Open menu icon"
            />
          )}
        </div>
      </div>
      <nav
        className={cx("mobile-nav", "pt-4", {
          "slide-down": showNav,
        })}
      >
        {showNav && (
          <ul className="flex flex-col items-center antialiased uppercase font-medium">
            <li className="mb-2 text-center active:text-teal-light">
              <Link to="/">The app</Link>
            </li>
            <li className="mb-2 text-center active:text-teal-light">
              <Link to="/about-the-schengen-area">About the Schengen area</Link>
            </li>
            <li className="mb-2 text-center active:text-teal-light">
              <Link to="/contact">Contact us</Link>
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
};
