import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { fbq } from "@hutsoninc/gatsby-plugin-facebook-pixel";

import AppStoreBtn from "../../images/button_download_app-store.svg";
export const Hero = ({ slice, context }) => {
  const content = slice.primary;

  const goToAppStore = (context) => {
    // pixel code
    fbq("track", "Lead", {
      content_name: "Redirect to Apple Store",
      content_category: "App Download Intent",
    });
    window.open(context, "_blank");
  };

  return (
    <section className="lg:max-h-[54rem] min-h-fit pt-10 md:pt-20 xl:pt-28 pb-10 md:pb-20 xl:pb-28 bg-gradient-to-br from-gradients-teal via-gradients-teal-mid to-powder-blue antialiased">
      <div className="container md:flex md:items-center">
        <div className="text-wrapper  md:w-1/2">
          <h1 className="text-4xl md:text-4xl xl:text-6xl font-semibold font-display text-white mb-5 md:mb-10">
            {content.h1_heading}
          </h1>
          <h2 className="text-2xl md:text-2xl xl:text-3xl text-white  mb-5 md:mb-14">
            {content.subheading}
          </h2>
          <div className="cta hidden md:block">
            <h3 className="text-xl text-white font-bold mb-2 md:mb-4">
              {content.cta}
            </h3>
            <div
              className="cursor-pointer"
              onClick={() => goToAppStore(context)}
            >
              <img
                src={AppStoreBtn}
                alt="AppStore button to download Schengen Simple for free"
              />
            </div>
          </div>
        </div>
        <div className="preview-image-wrapper flex md:w-1/2 pb-10 md:pb-0 ">
          <GatsbyImage
            image={content.app_preview.gatsbyImageData}
            alt={content.app_preview.alt}
            className="mx-auto"
          />
        </div>
        <div className="cta md:hidden text-center">
          <h3 className="text-xl text-white font-bold mb-4">{content.cta}</h3>
          <div className="cursor-pointer" onClick={() => goToAppStore(context)}>
            <img
              src={AppStoreBtn}
              alt="AppStore button to download Schengen Simple for free"
              className="mx-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
