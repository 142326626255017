import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Logo from "../images/logo.svg";

export const Footer = () => {
  const now = new Date();
  const year = now.getFullYear();

  return (
    <footer className="container pt-10 pb-5 flex flex-col md:flex-row md:items-center justify-between antialiased">
      <div>
        <Link to="/">
          <img src={Logo} alt="Schengen Simple logo" />
        </Link>
        <p className="text-sm font-thin mt-10 mb-10 hidden md:block">
          Copyright {year} Roycroft Labs Ltd
        </p>
      </div>

      <div className="flex flex-col md:flex-row self-start gap-10 md:gap-24 mt-4 md:mt-0">
        {/* <a
          href="https://apps.apple.com/app/apple-store/id1637045933?pt=122509372&ct=wwwschengensimple&mt=8"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/button_download_app-store.svg"
            alt="Apple Store button to download Schengen Simple for free"
            width={150}
          />
        </a> */}

        <ul className="md:self-start text-sm">
          <li className="hover:text-teal-light active:text-teal-light">
            <Link to="/terms-of-use">Terms and Conditions</Link>
          </li>
          <li className="hover:text-teal-light active:text-teal-light">
            <Link to="/privacy-policy">Privacy policy</Link>
          </li>
          <li className="hover:text-teal-light active:text-teal-light">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <p className="text-sm font-thin mt-6 md:hidden">
        Copyright {year} Roycroft Labs Ltd
      </p>
    </footer>
  );
};
