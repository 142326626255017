import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";

const Promo = () => {
  const heroScreenshot = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          body {
            ... on PrismicHomepageDataBodyHero {
              primary {
                app_preview {
                  gatsbyImageData(width: 300, placeholder: BLURRED)
                  alt
                }
              }
            }
          }
        }
      }
    }
  `);

  const screenshot =
    heroScreenshot.prismicHomepage.data.body[0].primary.app_preview;

  return (
    <section className="promo p-10">
      <div className="container flex flex-col md:flex-row">
        <div className="w-100 md:w-9/12 flex flex-col justify-center">
          <h2 className="text-2xl md:text-5xl font-semibold text-white mb-4 md:mb-8">
            Solve your Schengen problems
          </h2>
          <h3 className="text-base md:text-3xl text-charcoal mb-4 md:mb-10 md:mr-10">
            Get the Schengen Simple app and instantly know when you can travel
            and for how long
          </h3>
          <Link to="/" className="underline mb-10">
            <button className="pt-4 pb-4 px-8 rounded border-solid border-2 border-teal text-teal hover:text-white hover:bg-teal uppercase text-base md:text-2xl font-medium text-charcoal">
              Find out more
            </button>
          </Link>
        </div>
        <div className="w-9/12 md:w-4/12 md:ml-10">
          <GatsbyImage
            image={screenshot.gatsbyImageData}
            alt={screenshot.alt}
            className="mx-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Promo;
