import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

import { Footer } from "./Footer";
import { Header } from "./Header";

export const Layout = ({ metaTitle, metaDescription, children }) => {
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      (window.location.hostname == "schengen-landing.web.app" ||
        window.location.hostname == "schengen-landing.firebaseapp.com")
    ) {
      window.location.href = "https://schengensimple.com";
    } else return undefined;
  }, []);

  const headData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Schengen Simple | {metaTitle || headData.site.siteMetadata.title}
        </title>
        <meta
          name="description"
          content={metaDescription || headData.site.siteMetadata.description}
        />
        <meta
          property="image"
          content="https://images.prismic.io/schengensimple/688fe677-1730-454f-8d3e-87e70e9ac899_social-media-thumbnail_SQUARE.png?auto=compress,format"
        />

        {/* Facebook tags */}
        <meta
          property="og:title"
          content={metaTitle || headData.site.siteMetadata.title}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://schengensimple.com/" />
        <meta
          property="og:description"
          content={metaDescription || headData.site.siteMetadata.description}
        />
        <meta
          property="og:image"
          content="https://images.prismic.io/schengensimple/b5a0476e-2a44-4435-a366-6e9a57dfb709_social-media-thumbnail_LANDSCAPE.png?auto=compress,format"
        />

        {/* Twitter tags */}
        <meta
          name="twitter:creator"
          content={`Schengen Simple | ${
            metaTitle || headData.site.siteMetadata.title
          }`}
        />
        <meta
          name="twitter:card"
          content="https://images.prismic.io/schengensimple/688fe677-1730-454f-8d3e-87e70e9ac899_social-media-thumbnail_SQUARE.png?auto=compress,format"
        />
        <meta
          name="twitter:title"
          content={metaTitle || headData.site.siteMetadata.title}
        />
        <meta
          name="twitter:description"
          content={metaDescription || headData.site.siteMetadata.description}
        />

        {/* Other tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://schengensimple.com/" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};
