import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import cx from "classnames";
import { fbq } from "@hutsoninc/gatsby-plugin-facebook-pixel";

import AppStoreBtn from "../../images/button_download_app-store.svg";

export const Features = ({ slice, context }) => {
  const content = slice.primary;
  const features = slice.items;
  const appStoreURL = context;

  const goToAppStore = (appStoreURL) => {
    // pixel code
    fbq("track", "Lead", {
      content_name: "Redirect to Apple Store",
      content_category: "App Download Intent",
    });
    window.open(appStoreURL, "_blank");
  };

  return (
    <section
      className="container antialiased py-10 md:py-16 lg:py-24"
      id="app-overview"
    >
      <h2 className="text-center text-3xl lg:text-4xl xl:text-5xl font-medium mb-10 md:mb-16 lg:mb-24">
        {content.heading}
      </h2>
      <div className="lg:w-10/12 lg:ml-[8.333%]">
        {features.map((feature, i) => {
          const isEven = i % 2 === 0;

          return (
            <div
              className={cx(
                "feature",
                "flex",
                "mb-14",
                "flex-col",
                "lg:flex-row",
                {
                  "feature--odd": !isEven,
                }
              )}
              key={`feature--${i}`}
            >
              <GatsbyImage
                image={feature.feature_screenshot.gatsbyImageData}
                alt={feature.feature_screenshot.alt}
                className={cx("mx-auto", "order-2", {
                  "lg:order-1": isEven,
                  "lg:order-3": !isEven,
                })}
                objectFit="contain"
              />
              <div className="order-1 lg:order-2 lg:w-5/12 text-wrapper lg:mt-20">
                <h3
                  className={cx(
                    "text-2xl",
                    "lg:text-3xl",
                    "font-semibold",
                    "py-6",
                    "mb-6",
                    "px-10",
                    "lg:pl-0",
                    "colour-strip",
                    {
                      "gradient--1": isEven,
                      "gradient--2": !isEven,
                    }
                  )}
                >
                  {feature.feature_title}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: feature.feature_body.html,
                  }}
                  className="text-xl lg:text-2xl mb-8 px-10 lg:px-0 text-charcoal"
                />
              </div>
              <div
                className={cx("lg:w-1/12", "xl:w-auto", {
                  "order-1": !isEven,
                  "order-3": isEven,
                })}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="cta flex flex-col justify-center items-center gap-8 mt-20">
        <h4 className="text-2xl mb-2 font-medium">{content.cta}</h4>
        <div
          className="cursor-pointer"
          onClick={() => goToAppStore(appStoreURL)}
        >
          <img
            src={AppStoreBtn}
            alt="AppStore button to download Schengen Simple for free"
            className="mx-auto"
          />
        </div>
        <p>Available on iPhone, iPad and Mac</p>
      </div>
    </section>
  );
};
