import * as React from "react";
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";

import {
  logReferrer,
  logAnalyticsEvent,
  logGAPageView,
} from "./src/utils/analytics";

import { analytics } from "./firebase-config";

import "./src/styles/global.css";

export const onClientEntry = () => {
  // console.log("onClientEntry - window:", window);
  if (typeof window !== "undefined" && window.document) {
    // Store the referrer URL
    window.referrerURL = document.referrer;
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("onRouteUpdate - window:", window);

  // Log the referrer only on the first load (when there's no prevLocation)
  if (!prevLocation) {
    // console.log("!prevLocation - logging referrer");
    logReferrer();
  }

  if (
    analytics &&
    process.env.NODE_ENV === "production" &&
    typeof window !== "undefined"
  ) {
    // console.log("logging location:", location);
    logAnalyticsEvent("page_view", {
      page_path: location.pathname,
      page_params: location.search,
    });
  }

  // GA page view
  logGAPageView("page_watermelon", {
    page_path: location.pathname,
    page_params: location.search,
  });
};

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    {element}
  </PrismicProvider>
);
