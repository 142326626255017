import React from "react";
import { graphql } from "gatsby";

import AboutSchengenTemplate from "../templates/aboutSchengen";

const AboutSchengenWithData = ({ location, data }) => {
  return <AboutSchengenTemplate data={data} location={location} />;
};

export const schengenInfoQuery = graphql`query ($uid: String) {
  prismicSchengenInfoSection(uid: {eq: $uid}) {
    type
    data {
      meta_title
      meta_description
      table_of_contents_order
      table_of_contents_heading
      page_heading
      subsections {
        table_of_contents_label
        subheading
        body {
          richText
        }
      }
    }
  }
  allPrismicSchengenInfoSection(sort: {data: {table_of_contents_order: ASC}}) {
    nodes {
      uid
      data {
        table_of_contents_heading
        table_of_contents_order
        subsections {
          table_of_contents_label
          subheading
        }
      }
    }
  }
}`;

export default AboutSchengenWithData;
