import { analytics } from "../../firebase-config";
import { logEvent } from "firebase/analytics";
import ReactGA from "react-ga4";

//  Firebase tracking
export const logReferrer = () => {
  if (analytics && typeof window !== 'undefined' && window.referrerURL) {
    // console.log("inside logReferrer - window.referrerURL", window);
    logEvent(analytics, "referrer", {
      referrer_url: window.referrerURL, 
    });
  }
};

export const logAnalyticsEvent = (eventName, params) => {
  if (analytics) {
    // console.log(
    //   "inside logAnalyticsEvent - eventName:",
    //   eventName,
    //   "params",
    //   params
    // );
    logEvent(analytics, eventName, params);
  }
};

//  GA tracking
export const logGAPageView = (eventName, params) => {
  console.log("inside logGAPageView!", eventName, params);
  ReactGA.send({
    hitType: eventName,
    page_path: params.page_path,
    page_params: params.page_params,
  });
};

// const trackCustomEvent = () => {
//   if (typeof window !== "undefined" && window.gtag) {
//     window.gtag("event", "click", {
//       event_category: "Button",
//       event_label: "Subscribe",
//       value: "Subscribe Button Click",
//     });
//   }
// };
