import * as React from "react";
import { graphql } from "gatsby";

import HomepageTemplate from "../templates/homepage";

export const query = graphql`
  {
    prismicHomepage {
      data {
        title
        description
        body {
          ... on PrismicHomepageDataBodyHero {
            slice_type
            primary {
              h1_heading
              subheading
              cta
              app_preview {
                gatsbyImageData(width: 300, placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicHomepageDataBodyFeatures {
            slice_type
            primary {
              heading
              cta
            }
            items {
              feature_title
              feature_body {
                html
              }
              feature_screenshot {
                gatsbyImageData(width: 250, placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicHomepageDataBodyReviews {
            slice_type
            primary {
              section_heading
            }
            items {
              review_content
              review_author
            }
          }
          ... on PrismicHomepageDataBodyLeadGenerationBlock {
            slice_type
            primary {
              heading
              body_text
              extra_copy
              cta_copy
              cta_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const HomepageWithData = ({ data, location }) => {
  return <HomepageTemplate data={data} location={location} />;
};

export default HomepageWithData;
