import React from "react";
import PropTypes from "prop-types";
import Android from "../svg/Android";

function LeadGeneration({ slice }) {
  const content = slice.primary;

  return (
    <section className=" bg-teal-alpha-30 relative" id="android-web">
      <div className="container py-10 md:py-20 md:flex gap-10 lg:gap-20">
        <div className="md:w-2/3">
          <div className="flex gap-6">
            <h2 className="text-2xl md:text-3xl xl:text-4xl font-medium mb-4 md:mb-6">
              {content.heading}
            </h2>
            <Android className="w-12 -mt-6 rotate-12" />
          </div>
          <p className="text-md lg:text-xl mb-6">{content.body_text}</p>
        </div>
        <div className="md:w-1/3 flex flex-col gap-2 items-center justify-center md:justify-end text-center">
          <a
            href={content.cta_link.url}
            target="_blank"
            className="button cursor-pointer text-white w-full rounded-sm bg-teal-light hover:bg-teal p-3 uppercase max-w-xs"
          >
            {content.cta_copy}
          </a>
          <p className="text-sm text-charcoal mt-2 md:mb-0">
            {content.extra_copy}
          </p>
        </div>
      </div>
    </section>
  );
}

export default LeadGeneration;

LeadGeneration.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      statement: PropTypes.shape({ text: PropTypes.string }),
    }),
  }),
};
