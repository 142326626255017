import * as React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <div className="not-found">
    <h1>404</h1>
    <h3>Sorry, we couldn't find the page you are looking for.</h3>
    <p>
      <Link to="/">
        <button type="button">Return to homepage</button>
      </Link>
    </p>
  </div>
);

export default NotFoundPage;
