import React from "react";
import cx from "classnames";

import QuoteMark from "../../images/quote-marks.svg";

function Review({ review, index }) {
  return (
    <div className={cx(index > 3 && "hidden lg:block")}>
      <p className="rounded pt-4 text-charcoal italic">
        {review.review_content}
      </p>
      <p className="review-author mt-4 text-sm font-semibold">
        {review.review_author}
      </p>
    </div>
  );
}

function Reviews({ slice }) {
  const content = slice.primary;
  const reviews = slice.items;

  return (
    <section className="antialiased py-10 md:py-16 lg:py-24 bg-blue-v-light">
      <div className="container relative pb-20">
        <h2 className="text-center text-3xl lg:text-4xl xl:text-5xl font-medium mx-8 sm:mx-12 pt-10 md:pt-0 mb-6 md:mb-16 lg:mb-24">
          {content.section_heading}
        </h2>
        <img
          src={QuoteMark}
          alt="Open quotation mark"
          className="quotation-mark quotation-mark--open"
        />
        <div className="grid md:grid-cols-2 lg:grid-cols-3 items-center md:items-baseline md:justify-center gap-x-10 gap-y-4 md:gap-x-20 md:gap-y-6">
          {reviews.map((review, i) => {
            return <Review key={`review-${i}`} index={i} review={review} />;
          })}
        </div>
        <img
          src={QuoteMark}
          alt="Open quotation mark"
          className="quotation-mark quotation-mark--closed"
        />
      </div>
    </section>
  );
}

export default Reviews;
