import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SliceZone } from "@prismicio/react";

import { components } from "../components/slices";
import { Layout } from "../components/Layout";

const Homepage = ({ data, location }) => {
  const homepageSlices = data.prismicHomepage.data.body;

  const [appStoreUrl, setAppStoreUrl] = useState(
    "https://apps.apple.com/app/apple-store/id1637045933?pt=122509372&ct=wwwschengensimple&mt=8"
  );

  useEffect(() => {
    if (location.search.includes("fb1")) {
      setAppStoreUrl(
        "https://apps.apple.com/app/apple-store/id1637045933?pt=122509372&ct=facebook1&mt=8"
      );
    } else if (location.search.includes("ios_share")) {
      setAppStoreUrl(
        "https://apps.apple.com/app/apple-store/id1637045933?pt=122509372&ct=ios_share&mt=8"
      );
    } else {
      setAppStoreUrl(
        "https://apps.apple.com/app/apple-store/id1637045933?pt=122509372&ct=wwwschengensimple&mt=8"
      );
    }
  }, [location]);

  return (
    <Layout
      metaTitle={data.prismicHomepage.data.title}
      metaDescription={data.prismicHomepage.data.description}
    >
      <SliceZone
        slices={homepageSlices}
        components={components}
        context={appStoreUrl}
      />
    </Layout>
  );
};

Homepage.propTypes = {
  data: PropTypes.shape({
    prismicHomepage: PropTypes.shape({
      data: PropTypes.shape({
        body: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            primary: PropTypes.object,
            slice_type: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

export default Homepage;
